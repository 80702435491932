import { ref, useNavigation, useTracker, useRuntimeConfig, useRouteBaseName, useRoute, normalizeSlug } from '#imports';
import { HotelRecommendation, HotelRecommendationCity, HotelPopularDestination } from '@/types/hotel';

export const useHotelRecommendation = () => {
  const { navigationPath } = useNavigation();
  const getRouteBaseName = useRouteBaseName();
  const route = useRoute();

  const isHomepage = getRouteBaseName(useRoute()) === 'index';
  const activeCityRef = ref<HotelRecommendationCity>();

  const changeActiveCity = (city: HotelRecommendationCity) => {
    if (activeCityRef.value?.id !== city.id) {
      activeCityRef.value = city;

      useTracker().jitsuEvent('user-click-button', {
        event_name: 'recommended-hotel-switch-city',
        product: 'hotel',
        object_name: 'exclusive-recommendation',
        object_parameter: `filter_${city.id}`,
        city: city.name,
        city_id: city.id,
      });

      if (isHomepage) {
        useTracker().gtagEvent('homepage_recommendedhotel_switch_city', {
          city: activeCityRef.value?.name ?? '',
        });
      }
    }
  };

  const getHotelSearchPath = (city: HotelRecommendationCity) => {
    return navigationPath({
      path: '/hotel/city/' + normalizeSlug(`${city.enName.replace(' ', '-')}-${city.country}`, true),
    });
  };

  function trackPopularHotel(hotel: HotelRecommendation, city: { name: string; id: number }) {
    useTracker().jitsuEvent('user-click-placeholder', {
      event_name: 'recommended-hotel-click',
      product: 'hotel',
      object_name: 'exclusive-recommendation',
      object_parameter: JSON.stringify({
        city: city.name,
        city_id: city.id,
        hotel_id: hotel.id,
      }),
    });

    if (isHomepage) {
      useTracker().gtagEvent('homepage-recommendedhotel-click', {
        city: hotel.enName,
        hotel_id: hotel.id,
      });
    }
  }

  function trackPopularHotelNav(cityName = '', direction: string) {
    useTracker().jitsuEvent('user-click-button', {
      event_name: 'recommended-hotel-navclick',
      product: 'hotel',
      object_name: 'exclusive-recommendation',
      object_parameter: direction,
      screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
      city: cityName,
    });

    if (isHomepage) {
      useTracker().gtagEvent('recommended-hotel-navclick', {
        city: activeCityRef.value?.name ?? '',
      });
    }
  }

  function trackPopularHotelSlide(cityName = '') {
    useTracker().jitsuEvent('user-slide-navigation', {
      event_name: 'recommended-hotel-navslide',
      product: 'hotel',
      object_name: 'exclusive-recommendation',
      object_parameter: 'carousel',
      screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
      city: cityName,
    });

    if (isHomepage) {
      useTracker().gtagEvent('recommended-hotel-navslide', {
        city: activeCityRef.value?.name ?? '',
      });
    }
  }

  function trackPopularHotelSeeMore(city: { name: string; id: number }) {
    useTracker().jitsuEvent('user-click-button', {
      event_name: 'recommended-hotel-seemore-click',
      product: 'hotel',
      object_name: 'exclusive-recommendation',
      object_parameter: 'see-more-city',
      city: city.name,
      city_id: city.id,
    });
  }

  return {
    activeCityRef,
    changeActiveCity,
    trackPopularHotel,
    trackPopularHotelNav,
    trackPopularHotelSlide,
    getHotelSearchPath,
    trackPopularHotelSeeMore,
  };
};

export const useHotelCheapest = () => {
  const { navigationPath } = useNavigation();
  const getRouteBaseName = useRouteBaseName();
  const route = useRoute();

  const activeCityRef = ref<HotelRecommendationCity>();

  const changeActiveCity = (city: HotelRecommendationCity) => {
    if (activeCityRef.value?.id !== city.id) {
      activeCityRef.value = city;

      trackCheapHotelSwitchCity(city);
    }
  };

  const getHotelSearchPath = (city: HotelRecommendationCity) => {
    return navigationPath({
      path: '/hotel/city/' + normalizeSlug(`${city.enName.replace(' ', '-')}-${city.country}`, true),
    });
  };

  function trackCheapHotelNav(method: 'nav' | 'slide', cityName = '', direction?: string) {
    useTracker().jitsuEvent(method === 'nav' ? 'user-click-button' : 'user-slide-navigation', {
      product: 'hotel',
      object_name: 'cheap-prices',
      event_name: method === 'nav' ? 'cheaphotel-navclick' : 'cheaphotel-navslide',
      object_parameter: method === 'slide' ? 'carousel' : direction ?? '',
      city: cityName,
      screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
    });
  }

  function trackCheapHotel(hotel: HotelRecommendation, city: { id: number; name: string }) {
    useTracker().jitsuEvent('user-click-placeholder', {
      event_name: 'cheaphotel-click',
      product: 'hotel',
      object_name: 'cheap-prices',
      object_parameter: JSON.stringify({
        city_id: city.name,
        hotel_id: hotel.id,
      }),
    });
  }

  function trackCheapHotelSwitchCity(city: { name: string; id: number | string }) {
    useTracker().jitsuEvent('user-click-button', {
      event_name: 'cheaphotel-switch-city',
      product: 'hotel',
      city: city.name,
      object_name: 'cheap-prices',
      object_parameter: `filter_${city.id}`,
      city_id: city.id,
    });
  }

  function trackCheapHotelSeeMore(city: { name: string; id: number | string }) {
    useTracker().jitsuEvent('user-click-button', {
      event_name: 'cheaphotel-seemore-click',
      product: 'hotel',
      object_name: 'exclusive-recommendation',
      object_parameter: 'see-more-city',
      city: city.name,
      city_id: city.id,
    });
  }

  return {
    activeCityRef,
    changeActiveCity,
    getHotelSearchPath,
    trackCheapHotel,
    trackCheapHotelNav,
    trackCheapHotelSeeMore,
  };
};

export const useHotelPopularDestination = () => {
  const { navigationPath } = useNavigation();
  const config = useRuntimeConfig();
  const getRouteBaseName = useRouteBaseName();
  const route = useRoute();

  const getHotelSearchPath = (hotel: HotelPopularDestination) => {
    return navigationPath({
      path: '/hotel/city/' + normalizeSlug(`${hotel.nameEn.replace(' ', '-')}-${hotel.country}`, true),
    });
  };

  function getCityImage(hotelCityName: string) {
    const cityName = hotelCityName?.split(' ').join('-').toLowerCase();

    if (!cityName) {
      return '';
    }

    return `${config.public.cdnAssetUrl}/cities/320x180/${cityName}.png`;
  }

  function trackPopularDestinationHotel(hotel: HotelRecommendation) {
    useTracker().jitsuEvent('user-click-placeholder', {
      event_name: 'cheaphotel-click',
      object_name: 'fav-destination',
      object_parameter: JSON.stringify({
        city_id: hotel.id,
      }),
      product: 'hotel',
      city: hotel.name,
      screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
    });
  }

  function trackPopularDestinationHotelNav(method: 'nav' | 'slide', direction?: string) {
    useTracker().jitsuEvent(method === 'nav' ? 'user-click-button' : 'user-slide-navigation', {
      event_name: method === 'nav' ? 'popular-destination-hotel-navclick' : 'popular-destination-hotel-navslide',
      product: 'hotel',
      object_name: 'fav-destination',
      object_parameter: method === 'nav' ? direction ?? '' : 'carousel',
      screen_name: getRouteBaseName(route) === 'index' ? 'home' : (getRouteBaseName(route) as string),
    });
  }

  return {
    getHotelSearchPath,
    getCityImage,
    trackPopularDestinationHotel,
    trackPopularDestinationHotelNav,
  };
};
